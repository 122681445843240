import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Game from './components/escape/Game';
import NotFound from './components/shared/NotFound';
import { NAVIGATION } from './constants/constants';
import Video from './components/escape/Video';
import ScavengerGame from './components/scavenger/ScavengerGame';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={`/${NAVIGATION.ESCAPE}/:gameID/*`} element={<Game />} />
        <Route path={`/${NAVIGATION.ESCAPE}/:gameID/:puzzleNum/*`} element={<Game />} />

        <Route path={`/${NAVIGATION.SCAVENGER}/:gameID/*`} element={<ScavengerGame />} />
        <Route path={`/${NAVIGATION.SCAVENGER}/:gameID/:riddleNum/*`} element={<ScavengerGame />} />

        <Route path={`/${NAVIGATION.VIDEO}/:gameID/:puzzleNum/*`} element={<Video />} />

        {/* Catch-all route for undefined routes */}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </Router>
  );
}

export default App;
