import React from 'react';
import logo from '../../assets/images/logo.png'; 
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
       <Link to="/">
      <img src={logo} alt="Logo" className="logo" />
      </Link>
    </header>
  );
};

export default Header;
