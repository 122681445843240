import React from 'react';
import { MESSAGES } from '../../constants/constants';
import NavLinks from './NavLinks';

interface ErrorProps {
    message: string;
}

const ErrorMessage = ({message}: ErrorProps) => {
  return (
    <div>
      <p>{MESSAGES.ERROR.MESSAGE}</p>
      <p className="error">{message}</p>
      <NavLinks />
    </div>
  );
};

export default ErrorMessage;