import React from 'react';
import { MESSAGES } from '../../constants/constants';
import NavLinks from './NavLinks';

const NotFound = () => {
  return (
    <div>
      <h1>{MESSAGES.NOTFOUND.HEADER}</h1>
      <p>{MESSAGES.NOTFOUND.MESSAGE}.</p>
      <NavLinks />
    </div>
  );
};

export default NotFound;