import React from 'react';
import { MESSAGES } from '../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';

const NavLinks = () => {
  const navigate = useNavigate();

  return (
    <div className="navlinks">
        <p><Link to="#" onClick={(e) => {e.preventDefault(); navigate(-1);}}>{MESSAGES.GO_BACK}</Link></p>
        <p><Link to={`/`}>{MESSAGES.RETURN_HOME}</Link></p>        
    </div>
  );
};

export default NavLinks;