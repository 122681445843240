import React, { useState } from "react";
import { MESSAGES } from "../../constants/constants";

interface LockProps {
  correctCode: string; // The correct 3-digit code
  redirectURL: string; // The URL to redirect to when the code is correct
  onSuccess: () => void; // Function to call when the code is correct
}

const Lock = ({ correctCode, redirectURL, onSuccess }: LockProps) => {
  const [inputCode, setInputCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [shake, setShake] = useState<boolean>(false); 

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputCode(newValue);
  
    if (newValue === "") {
      setErrorMessage("");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputCode === correctCode) {
      onSuccess(); 
    } else {
      setErrorMessage(MESSAGES.INCORRECT_CODE); 
      setShake(true); 
      setTimeout(() => {
        setShake(false); 
      }, 2000);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={shake ? "shake" : ""}>
        <input
          type="text"
          value={inputCode}
          onChange={handleInputChange}
          placeholder="Enter 3-digit code"
          maxLength={3}
          required
        />
        <button type="submit">Unlock</button>
      </form>
      {errorMessage && <p className="error">{errorMessage}</p>} 
    </div>
  );
};

export default Lock;
