import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Puzzle from './Puzzle';
import { GameData } from './GameData';
import Header from '../shared/Header';
import { MESSAGES } from '../../constants/constants';
import Footer from '../shared/Footer';
import ErrorMessage from '../shared/ErrorMessage';

const Game = () => {
  const { gameID, puzzleNum } = useParams<{ gameID: string; puzzleNum: string }>();
  const puzzleNumfromURL = Number(puzzleNum);
  const [gameData, setGameData] = useState<GameData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGameData = async () => {
      if (!gameID) return;

      try {
        const url = `/data/${gameID}.json`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: GameData = await response.json();
        setGameData(data);
        setError(null); // Clear error on successful fetch
      } catch (error: unknown) {
        if (error instanceof Error) {          

          setError(`Error fetching game data for ${gameID}: ${error.message}`); 
          console.error(error);
        } else {          
          setError('An unknown error occurred: ' + String(error));
          console.error(error);
        }
      }
    };

    fetchGameData();
  }, [gameID]);

  return (
    <div>
      <Header />
      
      {error ? (
        <ErrorMessage message={error} />
      ) : !gameData ? (
        <div>{MESSAGES.LOADING}</div>
      ) : (
        <>
          <h1>{gameData.game.title}</h1>
          <Puzzle gameData={gameData} puzzleNum={puzzleNumfromURL} />
        </>
      )}
      
      <Footer />
    </div>
  );
};

export default Game;
