
export const MESSAGES = {
    INCORRECT_CODE: "Incorrect code. Please try again.",
    SUCCESS_MESSAGE: "Success! Redirecting to the next puzzle...",
    LOADING: "Loading puzzle...",
    COMPLETED_MESSAGE: "Congratulations! You've completed the game.",
    PROCEED: "Proceed >",
    RETURN_HOME: "Return to Home",
    GO_BACK: "< Go Back",
    ERROR: {
        HEADER: "Yikes",
        MESSAGE: "Sorry, we seem to have encountered an error:",
        NOT_FOUND: (puzzleNum: number) => `Could not find puzzle #${puzzleNum}. Check the URL?`
    },
    NOTFOUND: {
        HEADER: "404 - Page Not Found",
        MESSAGE: "Sorry, the page you're looking for doesn't exist.",
    }
};

export const PAGES = {
    HOME: {
        TITLE: "All Games",
        ESCAPE_GAMES: "Escape Room Games",
        SCAVENGER_GAMES: "Scavenger Hunts",
    },
};

export const NAVIGATION = {
    ESCAPE: "escape",
    SCAVENGER: "scavenger",
    VIDEO: "video", 
}

export const GAMES = {
    ASYLUM: {
        TITLE: "Escape the Asylum",
        ID: "asylum", // this must match the name of the json file and the ID inside the json
    },
    SECRET: {
        TITLE: "The Secret Society",
        ID: "secret", // this must match the name of the json file and the ID inside the json
    },
    CURSE: {
        TITLE: "Break the Curse",
        ID: "curse", // this must match the name of the json file and the ID inside the json
    },
};
