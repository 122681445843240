import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GameData } from './GameData';
import ErrorMessage from '../shared/ErrorMessage';
import { MESSAGES } from '../../constants/constants';

const Video = () => {
  const { gameID, puzzleNum } = useParams<{ gameID: string; puzzleNum: string }>();
  const puzzleNumfromURL = Number(puzzleNum);
  const [gameData, setGameData] = useState<GameData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [videoURL, setVideoURL] = useState<any | null>(null); 
  const [videoExists, setVideoExists] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchGameData = async () => {
      if (!gameID) return;
      try {
        const url = `/data/${gameID}.json`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: GameData = await response.json();
        setGameData(data);

        setError(null); // Clear error on successful fetch
      } catch (error: unknown) {
        if (error instanceof Error) {          
          setError(`Error fetching game data for ${gameID}: ${error.message}`); 
          console.error(error);
        } else {          
          setError('An unknown error occurred: ' + String(error));
          console.error(error);
        }
      }
    };

    fetchGameData();
  }, [gameID]);

  useEffect(() => { 
    const checkVideoURL = async () => {
        try {
        const response = await fetch(videoURL, {
            method: 'HEAD'
        });

        if (response.ok) {
            setVideoExists(true);
        } else {
            setVideoExists(false);
            setError(`Could not find video at ${videoURL}`);
        }
        } catch (error) {
            // this could be a CORS issue but we seem to be able to access the video still so not setting error
            setVideoExists(true); 
            //setError(`Could not find video at ${videoURL}`);
        }
    };
    if (puzzleNumfromURL && gameData) {
      const puzzle = gameData.puzzles.find((p) => p.num === puzzleNumfromURL);
      const videoURL = puzzle?.video || null;
      if (videoURL) {
        setVideoURL(videoURL);
        checkVideoURL();
      } else {
        setError('No video found for this puzzle');
      }
    }
  }, [gameData, puzzleNumfromURL, videoURL]);


  return (
    <div>
      {error ? (
        <ErrorMessage message={error} />
    ) : videoExists ? (      
        <video src={videoURL} controls width="300" preload="metadata"></video>     
    ) : (
        <div>{MESSAGES.LOADING}</div>
    )}
    </div>
    )
};

export default Video;
