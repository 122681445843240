import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/shared/Header';
import { NAVIGATION, PAGES, GAMES } from '../constants/constants';

const Home = () => {
  return (
    <div>
      <Header />
      <div className='home'>
        <h2>{PAGES.HOME.ESCAPE_GAMES}</h2>
        <ul>
          <li>
            <Link to={`/${NAVIGATION.ESCAPE}/${GAMES.ASYLUM.ID}/`}>{GAMES.ASYLUM.TITLE}</Link>
          </li>
        </ul> 
        <h2>{PAGES.HOME.SCAVENGER_GAMES}</h2>
        <ul>
          <li>
            <Link to={`/${NAVIGATION.SCAVENGER}/${GAMES.SECRET.ID}/`}>{GAMES.SECRET.TITLE}</Link>
          </li>
          <li>
            <Link to={`/${NAVIGATION.SCAVENGER}/${GAMES.CURSE.ID}/`}>{GAMES.CURSE.TITLE}</Link>
          </li>
        </ul> 
      </div>   
    </div>
  );
};

export default Home;
