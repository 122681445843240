import React, { useState } from 'react';

interface HelpProps {
  linkText: string;
  text: string;
}

function Help({ linkText, text }: HelpProps) {
  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => {
    setShowHelp((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleHelp} >
        {showHelp ? 'Hide '+linkText : 'Show '+linkText}
      </button>
      {showHelp && (    
        <p>{text}</p>      
      )}
    </div>
  );
}

export default Help;
