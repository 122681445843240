import React, { useEffect, useState } from "react";
import Lock from "./Lock";
import { GameData } from './GameData';
import Help from "../shared/Help"; 
import { Link, useNavigate } from 'react-router-dom';
import { NAVIGATION, MESSAGES } from "../../constants/constants";
import ErrorMessage from "../shared/ErrorMessage";

interface PuzzleProps {
  gameData: GameData;
  puzzleNum?: number; 
}

const Puzzle = ({ gameData, puzzleNum }: PuzzleProps) => {
  const [selectedPuzzle, setSelectedPuzzle] = useState<any | null>(null); 
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [isLastPuzzle, setLastPuzzle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); 
  const [puzzleNotFound, setPuzzleNotFound] = useState<boolean>(false); 
  const navigate = useNavigate();
  const totalPuzzles = gameData.puzzles.length;
  const gameID = gameData.game.id;

  useEffect(() => { 
    if (puzzleNum) {
      const puzzle = gameData.puzzles.find((p) => p.num === puzzleNum);
      setSelectedPuzzle(puzzle || null);     
    }
    setLoading(false); 
  }, [gameData.puzzles, puzzleNum]);

  useEffect(() => {
    if (loading) return; 
    setPuzzleNotFound(!selectedPuzzle); 
  }, [loading, selectedPuzzle]);

  // Handle success and redirection
  useEffect(() => {
    if (puzzleNum === totalPuzzles) {
      setLastPuzzle(true);
    }
    if (isCorrect && !isLastPuzzle) {
      const timer = setTimeout(() => {
        navigate(`/${NAVIGATION.ESCAPE}/${gameID}/${puzzleNum! + 1}`);
        setIsCorrect(false);  
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isCorrect, navigate, gameData.game.id, puzzleNum, isLastPuzzle, totalPuzzles, gameID]);

  // we are at the start of the game
  if (!puzzleNum) {
    const videoURL = gameData.game.video;
    return (<div>
      <video src={videoURL} controls width="300" preload="metadata"></video>
      <p><Link className="button" to={`/${NAVIGATION.ESCAPE}/${gameID}/1`}>{MESSAGES.PROCEED}</Link></p>
      </div>
    );
  }

  // isCorrect was set by the Lock component so we can show the success message
  if (isCorrect && !isLastPuzzle) {
    return (<div>
        <p className="success">{MESSAGES.SUCCESS_MESSAGE}</p>
      </div>
    );
  }

  // we reached the end of the game
  if (isCorrect && isLastPuzzle) {
    return (<div>
        <p className="success">{gameData.game.finishMessage}</p>
      </div>
    );
  }

  if (puzzleNotFound || !selectedPuzzle || !gameID) {
    return <ErrorMessage message={MESSAGES.ERROR.NOT_FOUND(puzzleNum!)} />;
  }

  if (loading) {
    return <div>{MESSAGES.LOADING}</div>; 
  }

  return (
    <div>
      <p className="small">Puzzle #{puzzleNum} of {totalPuzzles}:</p> 
      <p className="room">{selectedPuzzle.roomName}</p>
      <p className="debug"> unlock with code: {selectedPuzzle.correctCode}</p>
      <Lock 
        correctCode={selectedPuzzle.correctCode} 
        redirectURL={`/${NAVIGATION.ESCAPE}/${gameData.game.id}/${puzzleNum + 1}`} 
        onSuccess={() => setIsCorrect(true)} // Update state when success happens
      />
     
      <div className="help-container">
        <Help linkText="Hint 1" text={selectedPuzzle.hint1} />
        <Help linkText="Hint 2" text={selectedPuzzle.hint2} />
        <Help linkText="Answer" text={selectedPuzzle.answer} />
      </div>
    </div>
  );
};

export default Puzzle;
