import React, { useEffect, useState } from "react";
import { ScavengerGameData } from './ScavengerGameData';
import Help from "../shared/Help"; 
import { Link } from 'react-router-dom';
import { NAVIGATION, MESSAGES } from "../../constants/constants";
import ErrorMessage from "../shared/ErrorMessage";

interface RiddleProps {
  gameData: ScavengerGameData;
  riddleNum?: number; 
}

const Riddle = ({ gameData, riddleNum }: RiddleProps) => {
  const [selectedRiddle, setSelectedRiddle] = useState<any | null>(null); 
  const [loading, setLoading] = useState<boolean>(true); 
  const [riddleNotFound, setRiddleNotFound] = useState<boolean>(false); 
  const totalRiddles = gameData.riddles.length;
  const gameID = gameData.game.id;
  const [isLastRiddle, setLastRiddle] = useState<boolean>(false);
  let videoURL = gameData.game.video;

  useEffect(() => { 
    if (riddleNum) {
      const riddle = gameData.riddles.find((r) => r.num === riddleNum);
      setSelectedRiddle(riddle || null); 
      if (selectedRiddle?.order === totalRiddles) {
        setLastRiddle(true);   
      }
    }
    setLoading(false); 
  }, [gameData.riddles, riddleNum, selectedRiddle, totalRiddles ]);

  useEffect(() => {
    if (loading) return; 
    setRiddleNotFound(!selectedRiddle); 
  }, [loading, selectedRiddle]);


  // we are at the start of the game
  if (!riddleNum) {    
    return (<div>      
      <video src={videoURL} controls width="300" preload="metadata"></video>
      <p><Link className="button" to={`/${NAVIGATION.SCAVENGER}/${gameID}/1`}>{MESSAGES.PROCEED}</Link></p>
      </div>
    );
  }

  if (riddleNotFound || !selectedRiddle || !gameID) {
    return <ErrorMessage message={MESSAGES.ERROR.NOT_FOUND(riddleNum!)} />;
  }

  if (loading) {
    return <div>{MESSAGES.LOADING}</div>; 
  }

  return (
    <div>
      {isLastRiddle ?
        <p className="success">{gameData.game.finishMessage}</p>
        : <p className="small">Riddle #{selectedRiddle.order} of {totalRiddles}:</p>
  }
      <p className="debug"> This is riddle: {selectedRiddle.order} and it exists at url with {selectedRiddle.num}</p>
      {selectedRiddle?.video && <video src={selectedRiddle.video} controls width="300" preload="metadata"></video>}
     
      <div className="help-container">
        {selectedRiddle?.hint && <Help linkText="Hint 1" text={selectedRiddle?.hint1} /> }
        {selectedRiddle?.hint2 && <Help linkText="Hint 2" text={selectedRiddle?.hint2} /> }
        {selectedRiddle?.answer &&<Help linkText="Answer" text={selectedRiddle?.answer} />}
      </div>
    </div>
  );
};

export default Riddle;
